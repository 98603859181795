import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
  Data,
  getPropertiesStatus,
  getPropertiesTypes,
  PropertyStatusAttributes,
  PropertyTypeAttributes, sendContact, verifyToken
} from "../../services/dataService";
import {CAPTCHA_SITE_KEY} from "../../constants";
import {Link} from "react-router-dom";

interface FormDataElement {
  key: string;
  value: string;
}

declare global {
  interface Window {
    captchaOnLoad: () => void,
    grecaptcha: ReCaptchaInstance
  }
}

interface ReCaptchaInstance {
  ready: (cb: () => any) => any,
  execute: (siteKey: string|undefined, options: ReCaptchaExecuteOptions) => Promise<string>,
  render: (id: string, options: ReCaptchaRenderOptions) => any
}

interface ReCaptchaExecuteOptions {
  action: string
}

interface ReCaptchaRenderOptions {
  sitekey: string,
  size: 'invisible'
}

const convertPropertyStatus = (list: Data<PropertyStatusAttributes>[], currentLang: string, t:any): FormDataElement[] => {
  const result = list.map(propertyStatus => {
    let translatedValue = null;

    for (const translation of propertyStatus.attributes.translations) {
      if (translation.language === currentLang) {
        translatedValue = translation.value;
        break;
      }
    }

    if (!translatedValue) {
      translatedValue = propertyStatus.attributes.slug;
    }

    return {
      key: propertyStatus.attributes.slug,
      value: translatedValue,
    };
  });

  result.sort((a: FormDataElement, b: FormDataElement) => a.value.localeCompare(b.value));

  return [{ key: '', value: `${t('search-form.select')}` }, ...result];
}

const convertPropertyType = (list: Data<PropertyTypeAttributes>[], currentLang: string, t:any): FormDataElement[] => {
  const result = list.map(propertyType => {
    let translatedValue = null;

    for (const translation of propertyType.attributes.translations) {
      if (translation.language === currentLang) {
        translatedValue = translation.value;
        break;
      }
    }

    if (!translatedValue) {
      translatedValue = propertyType.attributes.slug;
    }

    return {
      key: propertyType.attributes.slug,
      value: translatedValue,
    };
  });

  result.sort((a: FormDataElement, b: FormDataElement) => a.value.localeCompare(b.value));

  return [{ key: '', value: `${t('search-form.select')}` }, ...result];
}

const validate = (elements:any) => {
  const { name, phone, email, operation, type, price, city, zone, description } = elements;
  const errors = [];

  if (name.value.length < 3) {
    errors.push("El nombre debe tener al menos 3 caracteres");
  }
  if (phone.value.length < 8) {
    errors.push("El teléfono debe contener al menos 5 caracteres");
  }
  if (email.value.length < 5) {
    errors.push("El email debe contener al menos 5 caracteres");
  }
  if (email.value.split("").filter((x:any) => x === "@").length !== 1) {
    errors.push("Introduzca una email válido");
  }
  if (email.value.indexOf(".") === -1) {
    errors.push("Introduzca una email válido");
  }
  if (operation.value.length < 1) {
    errors.push("La operación es obligatoria");
  }
  if (type.value.length < 1) {
    errors.push("El tipo de vivienda es obligatorio");
  }
  if (!parseInt(price.value)) {
    errors.push("El precio sólo debe contener números");
  }
  if (city.value.length < 2) {
    errors.push("La ciudad debe contener al menos 2 caracteres");
  }
  if (zone.value.length < 2) {
    errors.push("La zona debe contener al menos 2 caracteres");
  }
  if (description.value.length < 10) {
    errors.push("La descripción debe contener al menos 10 caracteres");
  }

  return errors;
}

const Contact = () => {
  const { t, i18n } = useTranslation();
  const [buttonText, setButtonText] = useState('');
  const [errors, setErrors] = useState<string[]>();
  const [messageSend, setMessageSend] = useState('');
  const [messageError, setMessageError] = useState('');
  const currentLanguage = i18n.resolvedLanguage;

  const [propertyStatusList, setPropertyStatusList] = useState<Data<PropertyStatusAttributes>[]>([]);
  const [propertyTypeList, setPropertyTypeList] = useState<Data<PropertyTypeAttributes>[]>([]);
  const [propertyStatusFormData, setPropertyStatusFormData] = useState<FormDataElement[]>([]);
  const [propertyTypeFormData, setPropertyTypeFormData] = useState<FormDataElement[]>([]);

  useEffect(() => {
    const loadScriptByURL = (id:string, url:string, callback:any) => {
      const isScriptExist = document.getElementById(id);

      if (!isScriptExist) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }

      if (isScriptExist && callback) callback();
    }

    // load the script by passing the URL
    loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${CAPTCHA_SITE_KEY}`, function () {
      console.log("Script loaded!");
    });
  }, []);

  useEffect(() => {
    setButtonText(t('contact-section.send-button'));
  }, [i18n.resolvedLanguage])

  // Load data only once
  useEffect(() => {
    getPropertiesStatus()
        .then(setPropertyStatusList);

    getPropertiesTypes()
        .then(setPropertyTypeList);
  }, []);

  // Callback when language or data changes
  useEffect(() => {
    Promise.resolve(propertyStatusList)
        .then(list => convertPropertyStatus(list, i18n.resolvedLanguage, t))
        .then(setPropertyStatusFormData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.resolvedLanguage, propertyStatusList]);

  // Callback when language or data changes
  useEffect(() => {
    Promise.resolve(propertyTypeList)
        .then(list => convertPropertyType(list, i18n.resolvedLanguage, t))
        .then(setPropertyTypeFormData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.resolvedLanguage, propertyTypeList]);

  const handleSubmit = async (e:any) => {
    e.preventDefault();
    setMessageSend('');
    setMessageError('');

    const errors = validate(e.target.elements);
    if (errors.length > 0) {
      setErrors(errors);
      return;
    }
    setButtonText(t('contact-section.sending-button'));
    const { name, phone, email, operation, type, price, city, zone, description, terms } = e.target.elements;
    let details = {
      nombre: name.value,
      telefono: phone.value,
      email: email.value,
      property_status_id: operation.value,
      property_type_id: type.value,
      precio: parseInt(price.value),
      poblacion: city.value,
      zona: zone.value,
      mensaje: description.value,
      acepta_politica: terms.value,
      idioma: i18n.resolvedLanguage
    };

    window.grecaptcha.ready(() => {
      window.grecaptcha.execute(CAPTCHA_SITE_KEY, {action: 'submit'}).then((token:any) => {
        let dataToken = {
          token: token
        }
        Promise.resolve(verifyToken(dataToken)).then(async response => {
          console.log(response)
          setButtonText(t('contact-section.send-button'));
          if (response.success) {
            await submitData(details, e);
          } else {
            setMessageError(t('contact-section.email-error'));
            setErrors([]);
          }
        })
      });
    });
  }

  const submitData = (details:any, e:any) => {
    Promise.resolve(sendContact(details)).then(response => {
      if(response.success){
        setMessageSend(t('contact-section.email-ok'));
        setErrors([]);
        e.target.reset();
      } else {
        setMessageError(t('contact-section.email-error'));
        setErrors([]);
      }
    });
  }


  return (
      <form onSubmit={handleSubmit}>
        {errors && errors.length > 0 &&
            <ul className="bg-red-100 list-disc list-inside mb-6 p-2 rounded-md">
              {errors.map((error: any) => (
                  <li key={Math.random()} className="text-sm">{error}</li>
              ))}
            </ul>
        }
        <div className="py-3">
          <h3 className="font-semibold text-xl text-white text-center">¿Interesad@? ¡Contáctanos!</h3>
        </div>

        <div className="py-3">
          <input type="text" name="name" placeholder={t('contact-section.name-input')}
                 className="px-4 py-4 w-full bg-gray-100 placeholder-gray-400 rounded-xl outline-none" required />
        </div>

        <div className="py-3">
          <input type="text" name="phone" placeholder={t('contact-section.phone-input')}
                 className="px-4 py-4 w-full bg-gray-100 placeholder-gray-400 rounded-xl outline-none" required />
        </div>

        <div className="py-3">
          <input type="text" name="email" placeholder={t('contact-section.email-input')}
                 className="px-4 py-4 w-full bg-gray-100 placeholder-gray-400 rounded-xl outline-none" required />
        </div>

        <div className="py-3">
          <textarea name="description" className="px-4 py-4 w-full h-52 bg-gray-100 placeholder-gray-400 rounded-xl outline-none peer resize-none" placeholder={t('contact-section.message')} required></textarea>
        </div>

        <div className="flex items-start mb-3">
          <div className="flex items-center h-5">
            <input id="terms" aria-describedby="terms" type="checkbox"
                   className="w-4 h-4 bg-gray-50 text-gray-500 rounded border border-gray-300 focus:ring-3 focus:ring-gray-300 dark:border-gray-600 dark:focus:ring-gray-600 dark:ring-offset-gray-800"
                   required />
          </div>
          <div className="ml-3 text-sm">
            <label htmlFor="terms" className="font-medium text-gray-500">{t('contact-section.agree')} <Link to={`/${currentLanguage}/privacy-policy`} className="text-blue-600 hover:underline dark:text-blue-500">{t('contact-section.terms-and-conditions-link')}</Link></label>
          </div>
        </div>

        <div className="py-3">
          <button
              className="w-full py-4 font-semibold text-lg text-gray-900 bg-white rounded-xl hover:bg-gray-300 transition ease-in-out duration-500">
            {buttonText}
          </button>
          {messageSend.length > 0 &&
              <span className="mt-2 text-green-600">{messageSend}</span>
          }
          {messageError.length > 0 &&
              <span className="mt-2 text-red-500">{messageError}</span>
          }
        </div>
      </form>
  );
}

export default Contact;
